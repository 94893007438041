




























































import {DateTime} from "luxon";

interface Fasce {
  'giorno':string,'orario_apertura': string,orario_chiusura:string,prenotazioni_possibili:any
}
interface Orari {
  'start_time':string,'end_time': string, 'orario_apertura':string, 'orario_chiusura':string
}
import {Component, Emit, Prop, Vue, Watch} from "vue-property-decorator";
import CampiService from "@/services/CampiService";
import {CampoClass, checkSport} from "@/Classes/CampoClass";
@Component
export default class EditFasce extends Vue {
  public form: CampoClass = new CampoClass();
  @Prop() disponibilita !:Array<any>
  @Prop() flex !: boolean
  @Prop() boolDisp!: boolean

  public giorni:any = [];
  public value:number = 0;
  public data:Fasce[] = [] ;
  public orari:Orari[] = [{'start_time':"00:00",'end_time': "00:00", 'orario_apertura':"00:00", 'orario_chiusura':"00:00"}]

  @Watch('boolDisp')
  disponibilita_watch(newVal: boolean) {
    if(newVal){
      this.data.splice(0, this.data.length);
      this.orari.splice(1, this.orari.length);
      this.giorni.splice(0, this.giorni.length);
      for(let disp of this.disponibilita){
          this.orari.push({
            start_time: disp.start_time,
            end_time: disp.endTime,
            orario_apertura: disp.orarioApertura,
            orario_chiusura: disp.orarioChiusura
          })

          this.giorni.push({
            value: disp.giorno_settimana,
            label: disp.nome,
            ora: disp.orarioApertura + "-" + disp.orarioChiusura
          })
        for(let fascia of disp.fasce){
          let temp:Fasce ={
            'giorno':disp.nome,'orario_apertura': fascia.inizio,'orario_chiusura':fascia.fine,'prenotazioni_possibili': fascia.intervalli
          }
          this.data.push(temp)
        }
        this.value=1
        this.SettaBoolAFalse(false)
      }
    }
  }

  @Watch('value')
  value_watch(newVal: any) {
      for(let orario of this.orari){
        orario.start_time =""
        orario.end_time =""
      }
  }
  @Emit()
  CalcoloIntervalli(){
    this.$emit("CalcoloIntervalli",{object: this.orari[this.value],kay:this.value-1})
  }

  @Emit()
  eliminaIntervallo(index:number) {
    this.$confirm('Sei sicuro di voler eliminare la fascia oraria ' + this.data[index].giorno + " " + this.data[index].orario_apertura +"/"+this.data[index].orario_chiusura +"?"  )
    .then(_ => {
    this.$emit("eliminaIntervallo",this.data[index])});
  }
  @Emit()
  SettaBoolAFalse(n:boolean) {
    this.$emit("SettaBoolAFalse",n)
  }
  public mounted() {
    this.data.splice(0, this.data.length);
    this.orari.splice(0, this.orari.length);
    this.giorni.splice(0, this.giorni.length);
    for(let disp of this.disponibilita){
      this.orari.push({
        start_time: disp.start_time,
        end_time: disp.endTime,
        orario_apertura: disp.orarioApertura,
        orario_chiusura: disp.orarioChiusura
      })

      this.giorni.push({
        value: disp.giorno_settimana,
        label: disp.nome,
        ora: disp.orarioApertura + "-" + disp.orarioChiusura
      })
      for(let fascia of disp.fasce){
        let temp:Fasce ={
          'giorno':disp.nome,'orario_apertura': fascia.inizio,'orario_chiusura':fascia.fine,'prenotazioni_possibili': fascia.intervalli
        }
        this.data.push(temp)
      }
      this.value=1
      this.SettaBoolAFalse(false)}
  }
}
