

























































































































































import {Component, Emit, Vue} from "vue-property-decorator";
import {Loading} from "element-ui";
import SportService from "@/services/SportService";
import CampiService, {campi_sport, CampoResponse} from "@/services/CampiService";
import {CampoClass, checkCampoRules, checkSport} from "@/Classes/CampoClass";
import {DateTime} from "luxon";
import EditFasce from "@/components/editFasce.vue";
@Component({
  components: {
    EditFasce
  }
})
export default class EditField extends Vue {

  public modificata_disponibilita:boolean= false;
  public campo: CampoResponse | undefined;
  public form: CampoClass = new CampoClass();
  public idCampo:number=-1;
  public idStruttura:number=-1;
  public maxDurata: number = 0;
  public startTime: any = null;
  public step1:boolean=false;
  public sport: checkSport[] = [];
  public rule: checkCampoRules = {
    nome: [{required: true, message: 'Nome del campo obbligatorio', trigger: 'blur'}],
    descrizione: [{required: true, message: 'Descrizione obbligatoria', trigger: 'blur'}],
    tipo_superfice: [{required: true, message: 'specificare la superficie del campo', trigger: 'blur'}]
  }


  //method
  public async RicercaSport(sport : campi_sport[]) {
      const response = await SportService.ListaSport();
      if (response.error) {
        this.$message.error(response.msg);
      } else {
        for (let i in response.data) {
          const temp: checkSport = {
            nome: response.data[i].nome,
            id: response.data[i].id,
            num_giocatori: response.data[i].num_giocatori,
            check: false,
            borderColor: "Red",
            prezzo: response.data[i].prezzo /100,
            durata: response.data[i].minuti,
            pagamento_persona: false
          };
          if(sport.length>0){
            console.log(sport)
            for(let y in sport){
              if(temp.id === sport[y].sport.id){
                temp.prezzo = sport[y].prezzo /100;
                temp.pagamento_persona = sport[y].pagamento_persona;
                temp.durata = sport[y].durata_partita;
                temp.check = sport[y].attivo;
              }
            }
          }
          this.sport.push(temp);
        }

    }
  }


  async CalcoloIntervalli(i: number) {
    let intervallo ={intervalli: await this.form.CalcoloIntervalli(this.calcoloDurataMax(), i),
      inizio: DateTime.fromISO(this.form.disponibilita[i].startTime).toFormat("HH:mm"),
      fine:DateTime.fromISO(this.form.disponibilita[i].endTime).toFormat("HH:mm")
    }
    this.form.disponibilita[i].fasce.push(intervallo)
    this.form.disponibilita[i].endTime = "";
    for (let j of this.form.disponibilita[i].fasce)
    {
      for (let x of j.intervalli)
      {
        this.form.disponibilita[i].startTime = x.fine;
      }
    }
  }
  public Back(){

    this.$confirm('Continuando verranno eliminae tutte le fasce orarie generate, vuoi continuare?')
    .then(_ => {
      for(let disp of this.form.disponibilita){
        disp.orario ="";
        disp.orarioApertura ="";
        disp.orarioChiusura ="";
        disp.endTime =null;
        disp.startTime =null;
        disp.fasce = []
      }
      this.step1=false;
    })
    .catch(_ => {});
  }

  controlloCheckSport() {
    for (let i of this.sport) {
      if(i.check)
        return true
    }
    return false;
  }
  calcoloDurataMax() {
    for (let i of this.sport) {
      if (i.durata > this.maxDurata && i.check)
        this.maxDurata = i.durata
    }
    return this.maxDurata;
  }
  cambioOrarioSport(item:checkSport){
    if(!this.controlloCheckSport()){
      this.$message({
        message: 'Devi selezionare almeno uno sport',
        type: 'warning'
      });
      item.check = true;
    }
    const durataMax:number = this.maxDurata;
    this.calcoloDurataMax()
    if(durataMax != this.maxDurata) {
    }
  }

  //EMIT
  SettaModificato(n: boolean) {
    this.modificata_disponibilita = n;
  }
  async CalcoloIntervalli_emit(object1: any) {
    const object =object1.object
    const key = object1.kay;
    this.form.disponibilita[key].startTime =object.start_time
        this.form.disponibilita[key].endTime =object.end_time
    let intervallo ={intervalli: await this.form.CalcoloIntervalli(this.calcoloDurataMax(), key),
      inizio:object.start_time,
      fine:object.end_time
    }
    const oraInizio = DateTime.fromISO(object.start_time)
    const oraFine = DateTime.fromISO(object.end_time)
    let flag = false;
    for(let fascia of this.form.disponibilita[key].fasce){
      if((oraInizio >=  DateTime.fromISO(fascia.inizio) && oraInizio <=  DateTime.fromISO(fascia.fine)) ||
          (oraFine >=  DateTime.fromISO(fascia.inizio) && oraFine <=  DateTime.fromISO(fascia.fine)) ||
          (oraFine >=  DateTime.fromISO(fascia.inizio) && oraFine <=  DateTime.fromISO(fascia.fine)) ||
          (oraInizio <  DateTime.fromISO(fascia.inizio) && oraFine >  DateTime.fromISO(fascia.fine))
      ){
        flag = true;
        break;
      }
    }
    if(flag)
    {
      this.$message.error("La nuova fascia non puo essere inserita poichè è compresa in una fascia gia esistente")
      return;
    }else{
      this.form.disponibilita[key].fasce.push(intervallo)
      this.modificata_disponibilita=true

    }
  }

  async elimina_emit(object: any) {
    const giorni = this.form.disponibilita;
    for(let i in giorni){
      if(giorni[i].nome === object.giorno){
        for(let y in giorni[i].fasce)
        if(object.orario_chiusura === giorni[i].fasce[y].fine && object.orario_apertura === giorni[i].fasce[y].inizio ){
          this.form.disponibilita[i].fasce.splice(+y,1)
          this.modificata_disponibilita=true
          return
        }
      }
    }
  }
  public async ModificaCampo() {
    let loadingInstance1 = Loading.service({fullscreen: true});
    let campo: any = {
      sport: [],
      disponibilita: [],
      sport_eliminati: [],
      flex: this.form.flex
    };
    for (let i in this.sport) {
      const prezzo = this.sport[i].prezzo.toFixed(2).replace('.', '').replace(',', '');
      if (this.sport[i].check)
        campo.sport.push({
          id: this.sport[i].id,
          prezzo: parseInt(prezzo),
          durata: this.sport[i].durata,
          pagamento_persona: this.sport[i].pagamento_persona
        })
    }
    if(this.campo != undefined)
      for(let i of this.campo?.campi_sport){
        let flag = true;
        for(let y of campo.sport){
          if(i.sport.id === y.id){
            flag=false;
            break;
          }
        }
        if(flag)
          campo.sport_eliminati.push({id: i.sport.id})
      }
    if (campo.sport.length === 0) {
      this.$message({
        message: 'Nessun sport selezionato',
        type: 'error'
      });
      loadingInstance1.close()
      return;
    }
    for (let disponibilita of this.form.disponibilita) {
      let giorno_settimana = disponibilita.giorno_settimana;
      for (let fascia of disponibilita.fasce) {
        campo.disponibilita.push({
          'giorno_settimana': giorno_settimana,
          'ora_inizio': fascia.inizio,
          'ora_fine': fascia.fine,
        })
      }
    }
    if (campo.disponibilita.length === 0) {
      this.$message({
        message: 'Nessuna fascia oraria presente',
        type: 'error'
      });
      loadingInstance1.close()
      return;
    }
    const response = await CampiService.modificaDisponibilita(this.idStruttura,this.idCampo, campo);
    loadingInstance1.close()
    if (!response.error) {
      this.$message({
        message: 'Campo aggiunto con successo',
        type: 'success'
      });
      this.$router.push("/campi");
    } else {
      this.$message.error(response.msg);
    }
  }

  public async mounted() {
    this.idCampo = +this.$route.params.id
    this.idStruttura = +this.$route.params.id_struttura
    const response = await CampiService.campo(this.idStruttura,this.idCampo)
    if(response.error){
      this.$message.error(response.msg);
      this.$router.push("/campi");
      return;
    }
    this.campo = response.data[0];
    this.form.flex = this.campo.flex;
    console.log(this.form.flex)
    await this.form.riempiFasciaOraria(this.idStruttura);
    await this.RicercaSport(this.campo.campi_sport);
    if(this.campo.campi_sport.length>0)
      for(let disponibilita of this.campo.disponibilita){
        this.form.disponibilita[disponibilita.giorno_settimana-1].startTime = DateTime.fromISO(disponibilita.ora_inizio).toFormat("HH:mm");
        this.form.disponibilita[disponibilita.giorno_settimana-1].endTime = DateTime.fromISO(disponibilita.ora_fine).toFormat("HH:mm");
        await this.CalcoloIntervalli(disponibilita.giorno_settimana-1)
      }
    this.modificata_disponibilita=true;
  }

}

