var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',[_c('el-col',{staticStyle:{"padding":"10px"},attrs:{"xs":24,"sm":24,"md":24,"lg":24,"xl":24}},[_c('el-select',{attrs:{"placeholder":"Select"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},_vm._l((_vm.giorni),function(item){return _c('el-option',{key:item.value,attrs:{"label":item.label,"value":item.value}},[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(item.label))]),_c('span',{staticStyle:{"float":"right","color":"#8492a6","font-size":"13px"}},[_vm._v(_vm._s(item.ora))])])}),1),_c('el-time-select',{attrs:{"placeholder":"Orario apertura","picker-options":{
                      start: _vm.orari[_vm.value].orario_apertura,
                      step: '00:30',
                      end:_vm.orari[_vm.value].orario_chiusura
                    }},model:{value:(_vm.orari[_vm.value].start_time),callback:function ($$v) {_vm.$set(_vm.orari[_vm.value], "start_time", $$v)},expression:"orari[value].start_time"}}),_c('el-time-select',{attrs:{"placeholder":"Orario chiusura","picker-options":{
                      start: _vm.orari[_vm.value].orario_apertura,
                      step: '00:30',
                      minTime:_vm.orari[_vm.value].start_time,
                      end:_vm.orari[_vm.value].orario_chiusura
                    }},model:{value:(_vm.orari[_vm.value].end_time),callback:function ($$v) {_vm.$set(_vm.orari[_vm.value], "end_time", $$v)},expression:"orari[value].end_time"}}),_c('el-button',{on:{"click":function($event){return _vm.CalcoloIntervalli()}}},[_vm._v("Conferma orari")])],1),_c('el-col',{staticStyle:{"padding":"10px"},attrs:{"xs":24,"sm":24,"md":24,"lg":24,"xl":24}},[_c('el-table',{staticStyle:{"width":"100%"},attrs:{"data":_vm.data}},[_c('el-table-column',{attrs:{"prop":"giorno","label":"Giorno"}}),_c('el-table-column',{attrs:{"prop":"orario_apertura","label":"Ora Apertura"}}),_c('el-table-column',{attrs:{"prop":"orario_chiusura","label":"Ora Chiusura"}}),(!_vm.flex)?_c('el-table-column',{attrs:{"prop":"prenotazioni_possibili","label":"Prenotazioni Possibili"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('label',[_vm._v(_vm._s(_vm.data[scope.$index].prenotazioni_possibili.length))])]}}],null,false,3774508055)}):_vm._e(),_c('el-table-column',{attrs:{"prop":"funzioni","align":"right"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"danger","icon":"el-icon-delete"},on:{"click":function($event){return _vm.eliminaIntervallo(scope.$index)}}})]}}])})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }